import React, { memo } from "react";
import Slider from "react-slick";
import Icon from "../icons/icon";
import { SliderStyled } from "./styled/customSlider.styled";

/* 
<CustomSlider 
newSettings= {newSettings} --Slider ayarları
arrowsBottomActive={true} -- arrowları açıyor ve arrowları aşağıya ortaya sürüklüyor ve noktaları kapatıyor.
countControl={true} -- 3 den az item geliyorsa slider kapatır, düz item yansıtır. 3 den fazla ise slidera döner.
></CustomSlider>
*/

function SampleNextArrow({ onClick, className }) {
  return (
    <div className={className} onClick={onClick}>
      <Icon name="icon-chevron-right" />
    </div>
  );
}

function SamplePrevArrow({ onClick, className }) {
  return (
    <div className={className} onClick={onClick}>
      <Icon name="icon-chevron-left" />
    </div>
  );
}

const CustomSlider = ({
  children,
  newSettings,
  arrowsBottomActive,
  countControl,
}) => {
  if (newSettings) {
    if (countControl) {
      newSettings = {
        ...newSettings,
        infinite: countControl < 3 ? false : true,
        slidesToShow: countControl < 3 ? countControl : 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      };
    }
    newSettings = {
      ...newSettings,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
  }
  const settings = newSettings || {
    dots: arrowsBottomActive ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

 // console.log("settings", settings);
  return (
    <SliderStyled className="customSlider">
      <Slider
        className={arrowsBottomActive ? "customSlider-arrows" : ""}
        {...settings}
      >
        {children}
      </Slider>
    </SliderStyled>
  );
};

export default memo(CustomSlider);
