import styled from "styled-components";
import settingsStyled from "../../../../Assets/styled/settings.styled";

export const SliderStyled = styled.div`
  width: 100%;
  height: 100%;

  .customSlider {
    &-arrows {
      .slick {
        &-arrow {
          width: 42px;
          height: 42px;
          color: #002255;
          &::before {
            content: none;
          }

          .iconMain {
            border: 1px solid #eff3f9;
            box-shadow: 0px 24px 28px -16px rgba(0, 0, 0, 0.1);
          }
        }
        &-prev {
          ${settingsStyled.arrowPositionPrev}
        }

        &-next {
          ${settingsStyled.arrowPositionNext}
        }
      }
    }
  }
  .slick {
    &-arrow {
      z-index: 1;
      &::before {
        content: none;
      }
      .iconMain {
        svg {
          width: 32px;
          height: 32px;
          color: #005bbb;
        }
      }
    }

    &-dots {
      bottom: -55px;
      li {
        button {
          &::before {
            font-size: 10px;
            width: 14px;
            height: 14px;
            color: #365097;
          }

          &:hover{
            opacity: 0.75;
          }
        }
      }

      .slick-active {
        button {
          &::before {
            color: transparent;
            background: #365097;
            font-size: 14px;
            border: 2px solid #365097;
            border-radius: 50px;
            opacity: 1;
          }
        }
      }
    }
  }
`;
